<template>

    <!-- Booking -->
    <div class="booking-frm line-booking" v-bind:class="[ is_homepage ? 'shadow' : 'booking-frm-p-rates' ]">
        <div class="row mx-0">
            <div class="col px-0">
                <div class="d-inline-block pl-0">
                    <div class="custom-control custom-checkbox container-return-place">
                        <input type="checkbox" class="custom-control-input custom-control-input-alt" id="return-somewhere-else" data-active="0">
                        <label class="mb-3 return-somewhere custom-control-label custom-control-label-alt" style="line-height: 2" v-if="lang == 'es'" for="return-somewhere-else">Devolver en otro lugar</label>
                        <label class="mb-3 return-somewhere custom-control-label custom-control-label-alt" style="line-height: 2" v-else for="return-somewhere-else">Return somewhere else</label>
                    </div>
                </div>
            </div>
        </div>

        <form id="booking-form" action="" name="booking-form" class="booking-car active">
            <!-- inputs hidden -->
            <input type="hidden" id="language" name="language">
            <input type="hidden" id="urlpost" name="urlpost">
            <input type="hidden" id="type-car" name="type_car" value="0">
            <input type="hidden" id="provider_id" name="provider_id">

            <!-- Pickup & dropoff options -->
            <div class="row mx-0">
                <!-- Pick up -->
                <div class="form-group col-lg-4 col-sm-12 pl-0 pr-sm-1 pr-0 pickup">
                    <div class="input-group fw600">
                        <select class="form-control form-control-sm" id="pickup" name="pickup">
                            <slot></slot>
                        </select>
                    </div>

                </div>

                <!-- Drop off -->
                <div class="form-group col-lg-2 col-sm-6 pl-0 pr-lg-1 pr-0 dropoff-selector dropoff">
                    <div class="input-group fw600">
                        <select class="form-control" id="dropoff" name="dropoff">
                            <slot></slot>
                        </select>
                    </div>
                </div>

                <!-- Calendar options -->
                <div class="form-group col-lg-2 col-sm-6 pl-0 pr-sm-1 pr-0 date-time">
                    <input type="date" id="pickup-date" name="pickup_date" class="datetimepicker datepicker form-control" readonly/>
                </div>

                <div class="form-group col-lg-1 col-sm-6 pl-0 pr-lg-1 pr-0 date-time">
                    <input type="time" id="pickup-time" name="pickup_hour" class="datetimepicker timepicker form-control" readonly/>
                </div>

                <div class="form-group col-lg-2 col-sm-6 pl-0 pr-sm-1 pr-0 date-time">
                    <input type="date" id="dropoff-date" name="dropoff_date" class="datetimepicker datepicker form-control" readonly/>
                </div>

                <div class="form-group col-lg-1 col-sm-6 px-0 date-time">
                    <input type="time" id="dropoff-time" name="dropoff_hour" class="datetimepicker timepicker form-control" readonly/>
                </div>
                <!-- End Calendar options -->

                <div class="form-button col-lg-2 col-md">
                    <div class="w-100">
                        <input id="booking-buttom" class="btn btn-search mb-0 w-100 border-0 h-100 btn-main-light-outlinehover no-decoration" type="submit" :value="reserve_button_text"/>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <!--End Booking -->

</template>

<script>

import Select2 from 'select2'
import flatpickr from 'flatpickr'

export default {
    props: ['settings'],
    data() {
        return {
            lang: "",
            is_homepage: true,
            reserve_button_text: "Buscar"
        }
    },
    mounted() {

        var site_id         = this.settings.site_id
        var domain_url      = this.settings.domain_url
        var location_id     = this.settings.location_id
        var provider_id     = this.settings.provider_id
        var lang            = this.settings.lang
        var dropoff_id_user = this.settings.location_dropoff_id
        var pickup_date     = this.settings.pickup_date
        var dropoff_date    = this.settings.dropoff_date
        var pickup_hour     = this.settings.pickup_hour
        var dropoff_hour    = this.settings.dropoff_hour
        var tipo_auto       = this.settings.tipo_auto
        var is_homepage     = this.settings.homepage
        var params          = {lang}

        this.lang           = lang
        this.is_homepage    = is_homepage
        var blockUI_message = 'Cargando...'
        if (lang == 'en') {
            this.reserve_button_text = 'Book a car'
            blockUI_message          = 'Please wait..'
            $('#control-tabs-booking').html('Find my booking')
        }

        var blockUI_conf = {
            message: '<div class="loader-container-block-ui"> <div class="loader-block-ui"></div> </div>',
            css: {
                border: 'none',
                padding: '5px',
                backgroundColor: 'transparent',
                '-webkit-border-radius': '10px',
                '-moz-border-radius': '10px',
                color: '#fff',
                width: '60%',
                left:'20%'
            }
        }

        $('#language').val(lang)
        $('#provider_id').val(provider_id)


        /** Selects pickup, drop off and type car */
        $('#pickup').select2({
            theme: 'bootstrap4',
            placeholder: this.settings.pickup_placeholder
        })

        /** Clicked when is empty */
        $('#select2-pickup-container').on('click', function() {
            //load after first render when clicked the select
            $('#pickup').select2('close');
            loadInit(true);
            // getTypeCar(provider_id, location_id, false)
        })

        // On change event function
        $('#pickup').on('select2:select', function (event) {
            // Call new date from new location pickup selected
            location_id = $(this).val()
            getDropoff(location_id)
            // getTypeCar(event.params.data.provider, event.params.data.id, false)
            $('#urlpost').val(event.params.data.url)
            $('#provider_id').val(event.params.data.provider)
        })

        /**
         * place to return
         */
        $('#return-somewhere-else').on('change', function(e) {
            var active  = parseInt($(this).data('active'))
            var checked = $(this).prop('checked')

            $('.dropoff-selector').toggle();
            $('#pickup').parent().find(".select2-container").css('width', '100')
            $('#dropoff').parent().find(".select2-container").css('width', '100')

            if (checked) {
                //(0) it was activated
                $('.dropoff-selector').slideDown()
                $(this).data('active', 1)

                let dropoff_id = $('#dropoff').val()
                // If has data not make request
                if (dropoff_id == null) { getDropoff(location_id, false) }
                $(this).parents('.form-group').addClass('pickup-half')
                $(this).parents('.form-group').removeClass('pickup')

                $('.return-somewhere').addClass('active')

                $('.pickup').addClass('col-lg-2')
                $('.pickup').removeClass('col-lg-4')

                if( $( window ).width() >= 576 ) {
                    $('.pickup').removeClass('col-sm-12')
                    $('.pickup').addClass('col-sm-6')
                }

            } else {
                //(1) it was desactived
                $('.dropoff-selector').slideUp()
                $(this).data('active', 0)
                $('#dropoff').val(location_id).trigger('change')

                $('.return-somewhere').removeClass('active')

                $('.pickup').removeClass('col-lg-2')
                $('.pickup').addClass('col-lg-4')

                if( $( window ).width() >= 576 ) {
                    $('.pickup').removeClass('col-sm-6')
                    $('.pickup').addClass('col-sm-12')
                }
            }
        })

        $('#dropoff').select2({
            theme: 'bootstrap4',
            placeholder: this.settings.pickup_placeholder
        })

        /** Clicked when is empty */
        $('#select2-dropoff-container').on('click', function() {
            // Load data first time
            $('#dropoff').select2('close');
            getDropoff(location_id, true)
        });


        // Calendars flatpickr
        var minDate = (typeof pickup_date == 'undefined') ? moment().add(1, 'days').format('YYYY-MM-DD') : moment(pickup_date).format('YYYY-MM-DD')
        $('#pickup-date').flatpickr({
            disableMobile: true,
            dateFormat: "Y-m-d",
            defaultDate: minDate,
            minDate: minDate,
            onChange: function(selectedDates, dateStr, instance) {
                var nextDay = moment(dateStr).add(1, 'days').format('YYYY-MM-DD')
                var drop_calendar = $('#dropoff-date').flatpickr({
                    disableMobile: true,
                    dateFormat: "Y-m-d",
                    defaultDate: nextDay,
                    minDate: nextDay
                })

                drop_calendar.open()
            }
        })

        var time = (typeof pickup_hour == 'undefined') ? moment("2099-12-30 11:00") : moment("2099-12-30 " + pickup_hour)
        $('#pickup-time').flatpickr({
            disableMobile: true,
            enableTime: true,
            noCalendar: true,
            dateFormat: "H:i",
            time_24hr: true,
            defaultDate: moment(time).format('HH:mm')
        })

        var minDateD = (typeof dropoff_date == 'undefined') ? moment(minDate).add('1', 'days').format('YYYY-MM-DD') : moment(dropoff_date).format('YYYY-MM-DD')
        $('#dropoff-date').flatpickr({
            disableMobile: true,
            dateFormat: "Y-m-d",
            defaultDate: minDateD,
            minDate: moment(minDate).add(1, 'days').format('YYYY-MM-DD')
        })

        var timeD = (typeof dropoff_hour == 'undefined') ? moment("2099-12-30 11:00") : moment("2099-12-30 " + dropoff_hour)
        $('#dropoff-time').flatpickr({
            disableMobile: true,
            enableTime: true,
            noCalendar: true,
            dateFormat: "H:i",
            time_24hr: true,
            defaultDate: moment(timeD).format('HH:mm')
        });

        // Avoid multiple click
        $(this).prop('disabled', false)

        $('#booking-form #pickup, #booking-form #return-somewhere-else, #booking-form #pickup-date, #booking-form #pickup-time, #booking-form #dropoff-date, #booking-form #dropoff-time, #booking-form #type-car').on('change', function(event) {
                //console.log('Select2 ha cambiado en:', event.target.id, 'con valor:', $(this).val());
                var params = $("#booking-form").serialize()
                ajaxRequest('/booking', params, 'POST', {"X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")}).then(response => {
                }).finally(() => {
                });
        });

        //submit form
        $('#booking-form').on('submit', function (event) {
            event.preventDefault()
            event.stopPropagation()

            $('#booking-buttom').prop('disabled', true)

            let url      = $('#urlpost').val()
            let lang     = $('#language').val()
            window.location = decodeURIComponent(url);
            $('#booking-buttom').prop('disabled', false)
            // let redirect = (lang === 'en') ? url.substring(3, url.length) : url
            /*var params   = $(this).serialize()

            if (is_homepage) { $.blockUI(blockUI_conf) };

            ajaxRequest('/booking', params, 'POST', {"X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")}).then(response => {
                window.location = decodeURIComponent(url)
            }).finally(() => {
                //$.unblockUI();
                //$('#booking-buttom').prop('disabled', false)
            })*/
        })

        /** In case we want to load everything from
         the begining you can use this */
        $(document).ready(function(event) {
            $.unblockUI();
            loadInit()

            $('#return-same-place').prop('checked', true)
            $('#return-same-place').next().addClass('active')
            // $('#return-somewhere-else').data('active', 0)

            if (typeof dropoff_id_user != 'undefined' && location_id != dropoff_id_user && is_homepage == false) {
                $('#return-somewhere-else').click()
            }

            $('#booking-buttom').prop('disabled', false)
        })

        function loadInit (openDropdown = false) {
            //load data from booking first render

            $.ajax({
                url: "/pickups",
                dataType: 'json',
                data: params,
                async: true,
            }).done(function(response) {

                if (response.error) {
                    Swal.fire('Ups!', 'Something went wrong! ' + response.message, 'error')
                    return true;
                }

                var result = $.map(response.data, function (item, index) {
                    if (lang == 'en') {
                        var url = item.booking_url.replace(/\/en/, '');
                    }else{
                        var url = item.booking_url.replace(/\//, '/es/');
                    }
                    if (item.id == location_id) {$('#urlpost').val(url)}
                    return {id: item.id, text: item.name, provider:item.provider_id, code: item.code, url: url}
                })

                var provider_id = 0;
                // Move to first element
                $(result).each(function(index, item) {
                    if (item.id == location_id) {
                        result.unshift(item)
                        result.splice(index + 1 , 1)
                        provider_id = item.provider
                    }
                })

                $('#pickup').empty()
                $('#pickup').select2({
                    theme: 'bootstrap4',
                    data: result,
                    selectOnClose: true
                })

                $('#pickup').val(location_id)
                if (openDropdown) { $('#pickup').select2('open') }
            }).fail(function(xhr, status, error) {
                Swal.fire('Ups!', 'Something went wrong!: ' + xhr.responseJSON.error, 'error');
            })
        }

        //Request to fill dropoff selector
        const getDropoff = (location_id, openDropdown = false) => {

            params.site_id     = this.settings.site_id
            params.domine      = this.settings.domain_url
            params.lang        = lang
            params.location_id = location_id

            ajaxRequest('/dropoffs', params).then(response => {

                if (response.error) {
                    Swal.fire('Ups!', 'Something went wrong! ' + response.message, 'error')
                    return true
                }

                //processing data
                response.data.filter((location) => {
                    if (location.id == location_id) {

                        var result = [];
                        $.each(location.dropoffs, function(index, dropoff) {
                            result.push({id: dropoff.id, text: dropoff.name});
                        });

                        $('#dropoff').empty()
                        $('#dropoff').select2({
                            theme: 'bootstrap4',
                            data: result
                        });

                        $('#dropoff').parent().find(".select2-container").css('width', '100')



                        // var active = parseInt($('#return-somewhere-else').data('active'));
                        // if (!active) {
                        //     $('#dropoff').val(location_id).trigger('change')
                        // }

                        if ($('#return-somewhere-else').is(':checked') && $('#return-somewhere-else').val() === 'other-place') {
                            $('#dropoff').val(location_id).trigger('change')
                        }
                        else {
                            // Is active
                            if (typeof dropoff_id_user != 'undefined') { $('#dropoff').val(dropoff_id_user).trigger('change') }
                            if (openDropdown) { $('#dropoff').select2('open') }
                        }
                    }
                })
            })
        }

        //call ajax method
        const ajaxRequest = (url, params = [], method = "GET", headers = [], async = true) => {
            return new Promise((resolve, reject) => {
                $.ajax({
                    url : url,
                    method : method,
                    headers: headers,
                    dataType: 'json',
                    data : params,
                    async : true
                }).done(resolve).fail(function(error) {
                    Swal.fire(
                        error.status + ' - Error',
                        error.responseJSON.error,
                        'error'
                    );
                    console.log(error)
                });
            })
        }
    }
}

$('#pickup').parent().find(".select2-container").css('width', '100')
$('#dropoff').parent().find(".select2-container").css('width', '100')
</script>
